import { version } from '../../package.json';

export const baseUrl: string = 'https://qa.harperjames.co.uk';

export const environment = {
  production: true,
  apiUrl: `${baseUrl}/api`,
  appVersion: `${version}-stage`,
  appInsightsConnectionString:
    'InstrumentationKey=5e5b2cdc-0d84-40b1-af26-9c52808eb9e2;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/',

  config: {
    auth: {
      clientId: 'b7875d1b-9e0a-4ad9-8bb0-1fc0352809aa',
      authority: 'https://login.microsoftonline.com/ff9c468c-4463-4262-a31d-5401f37e4b9d',
      validateAuthority: true,
      redirectUri: '/',
      postLogoutRedirectUri: '/logout',
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
    resources: {
      demoApi: {
        resourceUri: 'https://graph.microsoft.com/v1.0/me',
        resourceScope: 'api://b7875d1b-9e0a-4ad9-8bb0-1fc0352809aa/ReadUser', // here use the client id of the Web API you registered
      },
    },
    scopes: {
      loginRequest: ['user.read'],
    },
  },
};
